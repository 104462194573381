var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email"},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nueva contraseña","label-for":"new_password"}},[_c('ValidationProvider',{attrs:{"name":"Nueva contraseña","rules":"required|min:6","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new_password","name":"new_password","type":"password"},model:{value:(_vm.client.new_password),callback:function ($$v) {_vm.$set(_vm.client, "new_password", $$v)},expression:"client.new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Repite nueva contraseña","label-for":"confirm_new_password"}},[_c('ValidationProvider',{attrs:{"name":"Repite nueva contraseña","rules":"required|min:6|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirm_new_password","name":"confirm_new_password","type":"password"},model:{value:(_vm.client.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.client, "confirm_new_password", $$v)},expression:"client.confirm_new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"16"}}),_vm._v(" Enviar ")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }