<template>
  <div class="container">
    <div class="row d-flex align-items-center" style="height: 100vh">
      <div class="col-md-4 offset-md-4 shadow p-4">
        <h1 class="text-primary text-center mb-1">
          Mensajeria Tavo
        </h1>

        <div class="text-center mb-3">
          <b-link :to="{ name: 'login' }">
            <small> <i class="fa fa-arrow-left mr-1"></i>Volver al login</small>
          </b-link>
        </div>

        <h5>Recuperar contraseña</h5>
        <!-- <small class="mb-2"
          >Escribe tu correo y te enviaremos un enlace para que puedas recuperar
          tu contraseña</small
        > -->
        <recovery-forgot-password-form />
      </div>
    </div>
  </div>
</template>

<script>
import RecoveryForgotPasswordForm from '@/components/RecoveryForgotPasswordForm'
//Bootsrap vue
import { BLink } from 'bootstrap-vue'
export default {
  components: {
    RecoveryForgotPasswordForm,
    BLink,
  },
}
</script>
